<template>
  <div id="page-links">
    <b-row class="mt-12">
      <b-col lg="2" />
      <b-col lg="8">
        <b-card title="Länkar" hide-footer>
          <v-list>
            <v-list-item v-for="item in links" :key="item.title">
              <v-list-item-content>
                <a :href="item.url"><v-list-item-title v-text="item.title"></v-list-item-title></a>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="2" />
      <b-col lg="8">
        <b-card 
          :title="$t('COMMON.SETTINGS')" class="mb-2 mt-8" hide-footer>
          <div>
            <b-form class="mt-8" @submit.prevent="save_data">
              <b-form-group
                id="input-group-post_register_url"
                label="URL som medlemmar ska skickas till efter registrering"
                label-for="input-post_register_url"
              >
                <b-form-input
                  id="input-post_register_url"
                  v-model.trim="post_register_url"
                  type="text"
                  :state="state_url_post_register"
                ></b-form-input>
                <b-form-text>{{ $t('PAGES.SETTINGS.REQUIRES_HTTP') }}</b-form-text>
              </b-form-group>

              <div>
                <b-form-group
                  id="input-group-post_renew_url"
                  label="URL som medlemmar ska skickas till efter förnyelse"
                  label-for="input-post_renew_url"
                >
                  <b-form-input
                    id="input-post_renew_url"
                    v-model.trim="post_renew_url"
                    :state="state_url_post_renew"
                    type="text"
                  ></b-form-input>
                  <b-form-text>{{ $t('PAGES.SETTINGS.REQUIRES_HTTP') }}</b-form-text>
                </b-form-group>
              </div>

              <div >
                <b-form-group
                  id="input-group-login_url_register"
                  :label="$t('PAGES.REGISTRATION.LOGIN_URL')"
                  label-for="input-login_url_register"
                >
                  <b-form-input
                    id="input-login_url_register"
                    v-model.trim="login_url_register"
                    :state="state_url_login_register"
                    type="text"
                  ></b-form-input>
                  <b-form-text>{{ $t('PAGES.SETTINGS.REQUIRES_HTTP') }}</b-form-text>
                </b-form-group>
              </div>

              <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

export default {
  name: 'links-settings',
  mixins: [ toasts ],
  components: {},
  computed: {
    ...mapGetters(['currentPeriodId', 'currentCompanyId']),
    state_url_post_register() {
      if (this.post_register_url) { return this.is_valid_url(this.post_register_url); }
      return null;
    },
    state_url_post_renew() {
      if (this.post_renew_url) { return this.is_valid_url(this.post_renew_url); }
      return null;
    },
    state_url_login_register() {
      if (this.login_url_register) { return this.is_valid_url(this.login_url_register); }
      return null;
    },
  },
  mounted() {
    this.load_data();
  },
  props: ['settings'],
  data() {
    return {
      links: [],
      post_register_url: '',
      post_renew_url: '',
      login_url_register: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldValue) {
      if (newValue) {
        this.load_data();
      }
    }
  },
  methods: {
    is_valid_url(str) {
      let url;
      try { url = new URL(str); }
      catch(e) { return false; }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    load_data() {
      this.links = [
        { title: this.$t('PAGES.SETTINGS.MEMBER_REG_PAGE'), url: '/register/' + this.currentCompanyId },
        { title: this.$t('PAGES.SETTINGS.MEMBER_LOGIN_PAGE'), url: '/login/' + this.currentCompanyId },
        { title: this.$t('PAGES.SETTINGS.USER_LOGIN_PAGE'), url: '/ml-main-login/' + this.currentCompanyId },
        { title: this.$t('PAGES.SETTINGS.PUBLIC_EVENTS_PAGE'), url: '/events/' + this.currentCompanyId }
      ],

      this.post_register_url = this.settings.post_register_url;
      this.post_renew_url = this.settings.post_renew_url;
      this.login_url_register = this.settings.login_url_register;

      axios
        .get(`/company/top?company_id=${this.currentCompanyId}`)
        .then(res => {
          this.links.push({ title: this.$t('PAGES.SETTINGS.MEMBER_LOGIN_ORG_PAGE'), url: '/login/' + res.data.company_id });
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hitta moderförening');
        });
    },
    save_data() {
      axios
        .put(`/company/company_settings/${this.currentCompanyId}`, {
          post_register_url: this.post_register_url,
          post_renew_url: this.post_renew_url,
          login_url_register: this.login_url_register
        })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });
    }
  }
};
</script>
