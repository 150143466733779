<template>
  <div class="px-5 mt-8" id="page-registration">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-form class="mt-8">
          <b-card class="mb-8">
            <h4 class="mb-8">{{$t('COMMON.LANG')}}</h4>
            <div class="radio-list mb-8">
              <label class="radio radio-outline radio-success mr-5">
                <input
                  type="radio"
                  v-model="local_registration.lang"
                  value="sv"
                  name="lang"
                />
                <span class="mr-2"></span>{{$t('COMMON.SV')}}
              </label>
              <label class="radio radio-outline radio-success mr-5">
                <input
                  type="radio"
                  v-model="local_registration.lang"
                  value="en"
                  name="lang"
                />
                <span class="mr-2"></span>{{$t('COMMON.EN')}}
              </label>
            </div>

            <h4>Förinställd medlemskapsartikel</h4>

            <div class="d-flex align-items-center mb-6">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="local_registration.use_default_shopitem"
                    @click="local_registration.use_default_shopitem = !local_registration.use_default_shopitem"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">Använd förinställd medlemskapsartikel</span>
              </div>
          </b-card>

          <b-card title="" class="mb-2 mx-auto mt-8" hide-footer>
            <h4 class="mb-8">Vårdnadshavare och familjemedlemmar</h4>
            <b-alert show variant="light">
              Enligt huvudregel, 9 kap. 1 § Föräldrabalken skall föreningar som ställer ekonomiska
              krav på en medlem (t.ex. medlemsavgift), kräva vårdnadshavares godkännande ifall
              personen är under 18 år.
            </b-alert>

            <div class="d-flex align-items-center mb-6">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text
                  >Max ålder med krav på vårdnadshavare</b-input-group-prepend
                >
                <b-form-input
                  type="number"
                  min="1"
                  max="200"
                  v-model="local_registration.age_max_parent"
                  required
                ></b-form-input>
              </b-input-group>
            </div>

            <div class="d-flex align-items-center mb-6">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  v-model="local_registration.family"
                  @click="local_registration.family = !local_registration.family"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer">Tillåt familjeregistrering</span>
            </div>

            <b-alert show variant="light"
              >Vad ska hända med vårdnadshavares data vid registrering? Ifall nedanstående checkbox
              är förkryssad så kommer vårdnadshavare som fylls i vid registrering att skapas och
              läggas till som äkta medlemmar, med egna konton. Ifall checkboxen EJ är förkryssad så
              kommer vårdnadshavare endast att ligga sparade på medlemmen och ej vara
              sökbara.</b-alert
            >

            <div class="d-flex align-items-center mb-6">
              <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                <input
                  type="checkbox"
                  name=""
                  v-model="local_registration.create_parent_members"
                  @click="local_registration.create_parent_members = !local_registration.create_parent_members"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer"
                >Skapa äkta medlemmar av vårdnadshavare</span
              >
            </div>

          </b-card>

          <b-card class="mb-8">

            <div class="d-flex align-items-center mb-6" v-if="local_registration && local_registration.age_req_all">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input type="checkbox" v-model="check_join[0]" />
                      <span></span>
                    </label>

                    <span class="ml-3 cursor-pointer"></span>
                  </div>
                </b-input-group-prepend>
                <b-input-group-prepend is-text>Person måste vara minst ålder</b-input-group-prepend>
                <b-form-input
                  style="min-height: 50px;"
                  type="number"
                  max="200"
                  v-model="local_registration.age_req_all[0]"
                  :disabled="!check_join[0]"
                ></b-form-input>
              </b-input-group>
            </div>

            <div class="d-flex align-items-center mb-6" v-if="local_registration && local_registration.age_req_all">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <div class="d-flex align-items-center">
                    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                      <input type="checkbox" v-model="check_join[1]" />
                      <span></span>
                    </label>

                    <span class="ml-3 cursor-pointer"></span>
                  </div>
                </b-input-group-prepend>
                <b-input-group-prepend is-text>Person måste vara högst ålder</b-input-group-prepend>
                <b-form-input
                  style="min-height: 50px;"
                  type="number"
                  min="18"
                  max="200"
                  v-model="local_registration.age_req_all[1]"
                  :disabled="!check_join[1]"
                ></b-form-input>
              </b-input-group>
            </div>


          </b-card>

          <!-- <b-alert show variant="light">
              Ger möjlighet för medlemmar som registrera sig att bli medlem i fler än en förening.
            </b-alert>

            <div class="d-flex align-items-center mb-6">
              <label
                      class="checkbox checkbox-lg checkbox-outline checkbox-success"
              >
                <input
                        type="checkbox"
                        name=""
                        v-model="registration.allow_multiple"
                        @click="registration.allow_multiple = !registration.allow_multiple"
                />
                <span></span>
              </label>

              <span class="ml-3 cursor-pointer">Tillåt registrering i flera föreningar</span>
            </div>-->

          <!--<div class="d-flex align-items-center mb-6" v-if="company.pc_id">
              <label
                      class="checkbox checkbox-lg checkbox-outline checkbox-success"
              >
                <input
                        type="checkbox"
                        name=""
                        v-model="registration.show_from_pc"
                        @click="registration.show_from_pc = !registration.show_from_pc"
                />
                <span></span>
              </label>
              <span class="ml-3 cursor-pointer"
              >Denna förening skall vara valbar ifall medlem registrerar sig i
                moderföreningen</span
              >
            </div>-->

            <RightSaveButton
              ref="save-button"
              :text="$t('COMMON.SAVE')"
              @clicked="save_clicked()"
            />

        </b-form>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";


:deep .btn-action {
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  outline: none !important;
  padding: 0.5rem 1rem;
  color: #a2a5b9;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  transition: all 0.3s ease;
  border-radius: 4px;
}

:deep .select-all {
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  outline: none !important;
  padding: 0.5rem 1rem;
  color: #a2a5b9;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  transition: all 0.3s ease;
  border-radius: 4px;
  cursor: pointer;
}

:deep .deselect-all {
  border: 0;
  background: none;
  outline: none !important;
  box-shadow: none;
  outline: none !important;
  padding: 0.5rem 1rem;
  color: #a2a5b9;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #c1c1c1;
  transition: all 0.3s ease;
  border-radius: 4px;
  cursor: pointer;
}

:deep .list-box-wrapper {
  font-family: Poppins, Helvetica, 'sans-serif';
}
</style>
<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  name: 'registration',
  props: ['company', 'regions','registration'],
  emits: ['updated'],
  components: {
    RightSaveButton
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  watch: {

    registration: {
      handler: function(val) {
        if (val) {
          this.local_registration = { ...val };

          if (this.local_registration.use_default_shopitem === undefined) {
            this.local_registration.use_default_shopitem = false;
          }

        }
      },
      deep: true
    },

    allow_multiple(newValue, oldValue) {
      this.local_registration.allow_multiple = newValue;
    },
    is_region(newValue, oldValue) {
      this.local_registration.is_region = newValue;

      if (newValue) {
        // they can not co-exist
        this.if_pcomp_reg_allow_reg = false;
      }
    },
    if_pcomp_reg_allow_reg(newValue, oldValue) {
      this.local_registration.if_pcomp_reg_allow_reg = newValue;

      if (newValue) {
        this.is_region = false;
      }
    },
    company(newValue, oldValue) {

      if (newValue) {
        for (var k in this.local_registration) {
          if (newValue.local_registration.hasOwnProperty(k) && newValue.local_registration[k] != undefined)
            this.local_registration[k] = newValue.local_registration[k];
        }
      }
    },
    check_join(newVal, oldVal) {
      newVal.filter((item, index) => {
        if (!item) {
          this.local_registration.age_req_all.splice(index, 1, null);
        }
      });
    }
  },
  mounted() {

    if (this.registration) {
      this.local_registration = { ...this.registration };
    }
    else {
      this.local_registration = {
        is_region: false,
        if_pcomp_reg_allow_reg: false,
        map_by_region: false,
        allow_multiple: false,
        create_parent_members: false,
        demand_parents: false,
        show_from_pc: false,
        join_cond: [
          { type: 'max', val: '120' },
          { type: 'min', val: '0' }
        ],
        pages: [],
        age_req_all: [0, 120],
        family: false,
        age_max_parent: 18,
        use_default_shopitem: false,
        ...this.company.registration,
      };
    }

    for (var k in this.regions) {
      this.sourceRegions.push({ code: k, name: this.regions[k].region });
    }

    this.sourceRegions.sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    // this.registration = this.company.registration;

    // if (this.registration === null || this.registration === undefined) {

    // }
    this.selectedRegistrationOption = this.registration.option;


    if (
      this.selectedRegistrationOption === null ||
      this.selectedRegistrationOption === undefined ||
      this.selectedRegistrationOption === ''
    ) {
      this.selectedRegistrationOption = 'simple';
    }

    if (this.local_registration.hasOwnProperty('is_region')) {
      this.is_region = this.local_registration.is_region;
    }

    if (this.local_registration.hasOwnProperty('if_pcomp_reg_allow_reg')) {
      this.if_pcomp_reg_allow_reg = this.local_registration.if_pcomp_reg_allow_reg;
    }

    if (this.local_registration.use_default_shopitem === undefined) {
      this.local_registration.use_default_shopitem = false;
    }
  },
  data: function() {
    return {

      local_registration: {},

      selectedRegistrationOption: 'simple',
      sourceRegions: [],
      destRegions: [],
      sourceCommunes: [],
      destCommunes: [],
      is_region: false,
      if_pcomp_reg_allow_reg: false,
      map_by_region: false,
      allow_multiple: false,
      registration: {
        lang: 'sv',
        is_region: false,
        if_pcomp_reg_allow_reg: false,
        map_by_region: false,
        allow_multiple: false,
        create_parent_members: false,
        demand_parents: false,
        show_from_pc: false,
        join_cond: [
          { type: 'max', val: '60' },
          { type: 'min', val: '18' }
        ],
        pages: [],
        age_req_all: [18, 20],
        family: false,
        age_max_parent: 0
      },
      check_join: [true, true]
    };
  },
  methods: {
    async save_clicked() {

      try {
        this.local_registration.option = this.selectedRegistrationOption;

        const res = await axios.put(`/company/${this.currentCompanyId}`, { registration: this.local_registration });

        if (res.status === 200) {
          this.toastr('success', this.$t('COMMON.OK'), 'Registreringen uppdaterades');
          this.$emit('updated', this.local_registration);
        }

      }
      catch (err) {
        console.error('save_clicked error', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera registrering');
      }

      this.$refs['save-button'].stop();
    },

    convertParentsToPersons() {},
    convertParentsToMembers() {},
    mapByRegionUpdate(value) {
      this.map_by_region = value;
      this.local_registration.map_by_region = value;
    },

    onChangeRegion: function({ source, destination }) {
      this.sourceRegions = source;
      this.destRegions = destination;

      this.sourceCommunes = [];

      for (var i = 0; i < this.destRegions.length; ++i) {
        // populate communes based on selected regions
        var region = this.regions[this.destRegions[i].code];
        for (var k in region.communes) {
          this.sourceCommunes.push({
            code: k,
            name: region.communes[k],
            region_code: region.region_code
          });
        }
      }

      // remove from source commune already selected dest communes
      for (var i = 0; i < this.destCommunes.length; ++i) {
        for (var j = 0; j < this.sourceCommunes.length; ++j) {
          if (this.destCommunes[i].code === this.sourceCommunes[j].code) {
            this.sourceCommunes.splice(j, 1);
            break;
          }
        }
      }

      this.sourceCommunes.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      this.destCommunes.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    onChangeCommune: function({ source, destination }) {
      this.sourceCommunes = source;
      this.destCommunes = destination;
    },
  }
};
</script>
