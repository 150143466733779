<template>
  <tr>
    <td>
      {{ domain }}
    </td>
    <td class="text-right pr-0">
      <a
        href="#"
        class="btn btn-icon btn-light btn-sm mx-3"
        @click="deleteDomain"
      >
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <!--begin::Svg Icon-->
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
          <!--end::Svg Icon-->
        </span>
      </a>
    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'domain-data-table-row',
  props: ['domain'],
  emits: ['deleteDomain', 'selectDomain'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {

    };
  },
  mounted() {},
  watch: {

  },
  methods: {
    deleteDomain() {
      this.$emit('deleteDomain', this.domain);
    },
    selectDomain() {
      this.$emit('selectDomain', this.domain);
    }
  }
};
</script>
