<template>
  <div class="d-flex align-items-center mb-4">
    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
      <input
        type="checkbox"
        name=""
        v-model="local_profilerow.checked"
        @change="changed_profile_row"
      />
      <span></span>
    </label>
    <span class="ml-3 cursor-pointer">{{ local_profilerow.name }}</span>
  </div>
</template>


<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'profile-row',
  props: ['profilerow'],
  emits: ['change'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_profilerow: {}
    };
  },
  mounted() {
    if (this.profilerow) {
      this.local_profilerow = { ...this.profilerow };
    }
  },
  watch: {
    profilerow: {
      handler: function(val) {
        this.local_profilerow = { ...val };

      },
      deep: true
    }
  },
  methods: {
    changed_profile_row() {
      this.$emit('change', this.local_profilerow);
    }
  }
};
</script>
