<template>
  <div class="px-5 mt-8" id="page-bank">
    <b-row>
      <b-col lg="2"></b-col>
      <b-col lg="8">
        <b-card title="" class="mb-2 mx-auto mt-8" hide-footer>
          <div>
            <b-form class="mt-8" @submit="saveData">
              <b-form-group id="input-group-bg" label="Bankgiro" label-for="input-bg">
                <b-form-input id="input-bg" v-model="form.bg" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-pg" label="Plusgiro" label-for="input-pg">
                <b-form-input id="input-pg" v-model="form.pg" type="text"></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-clearing_no"
                :label="$t('COMPANY.CLEARING_NO')"
                label-for="input-clearing_no"
              >
                <b-form-input
                  id="input-clearing_no"
                  v-model="form.clearing_no"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-account_no"
                :label="$t('COMPANY.ACCOUNT_NO')"
                label-for="input-account_no"
              >
                <b-form-input
                  id="input-account_no"
                  v-model="form.account_no"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-bank_name" label="Bank" label-for="input-bank_name">
                <b-form-input
                  id="input-bank_name"
                  v-model="form.bank_name"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-iban" label="IBAN" label-for="input-iban">
                <b-form-input
                  id="input-iban"
                  v-model="form.iban"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-swift_bic" label="SWIFT/BIC" label-for="input-swift_bic">
                <b-form-input
                  id="input-swift_bic"
                  v-model="form.swift_bic"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">Spara</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'bank-settings',
  components: {},
  props: ['company'],
  
  mixins: [ toasts ],
  
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      form: {
        company_id: '',
        bg: '',
        pg: '',
        clearing_no: '',
        account_no: '',
        bank_name: '',
        iban: '',
        swift_bic: ''
      }
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadData();
      }
    }
  },
  methods: {
    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }

      this.form.bg = this.company.bg;
      this.form.pg = this.company.pg;
      this.form.clearing_no = this.company.clearing_no;
      this.form.account_no = this.company.account_no;
      this.form.bank_name = this.company.bank_name;
      this.form.iban = this.company.iban;
      this.form.swift_bic = this.company.swift_bic;
    },
    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },
    saveData(e) {
      e.preventDefault();

      this.form.company_id = this.currentCompanyId;

      axios
        .put(`/company/${this.currentCompanyId}`, this.form)
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
            // this.loadUsers();
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });
    },

  }
};
</script>
