<template>
  <div class="d-flex align-items-center mb-4">
    <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
      <input
        type="checkbox"
        name=""
        v-model="local_requirement.checked"
        @change="checkbox_changed"
      />
      <span></span>
    </label>
    <span class="ml-3 cursor-pointer">{{ local_requirement.name }}</span>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'requirement-row',
  props: ['requirement'],
  emits: ['change'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_requirement: {},
      name: ''
    };
  },
  mounted() {
    if (this.requirement) {
      this.local_requirement = { ...this.requirement };
    }
  },
  watch: {
    requirement: {
      handler: function(val) {
        this.local_requirement = { ...val };
      },
      deep: true
    }
  },
  methods: {
    checkbox_changed() {
      this.$emit('change', this.local_requirement);
    }
  }
};
</script>
