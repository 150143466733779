<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Extradata</span>
      </h3>
      <div class="card-toolbar">
        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click="createExtra"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Ny Extradata</a
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
          <thead>
            <tr class="text-left">
              <th>ID</th>
              <th>Namn</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in extras">
              <ExtraDataTableRow
                v-bind:key="i"
                :extra="item"
                :currentCompanyId="currentCompanyId"
                @deleteExtra="deleteExtra"
                @selectExtra="selectExtra"
                @toastr="toastr"
              />
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>

<script>
import axios from 'axios';
import ExtraDataTableRow from '@/view/pages/ml/settings/extradata/ExtraDataTableRow.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'extras-table',
  props: ['extras', 'currentCompanyId'],
  emits: ['selectExtra', 'deleteExtra', 'createExtra'],
  components: {
    ExtraDataTableRow
  },
  mixins: [ toasts ],
  methods: {
    selectExtra(id) {
      this.$emit('selectExtra', id);
    },
    deleteExtra(id) {
      this.$emit('deleteExtra', id);
    },
    createExtra() {
      this.$emit('createExtra');
    }
  },
  data() {
    return {

    };
  }
};
</script>

