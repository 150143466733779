<template>
  <tr>
    <td>
      {{ local_title.name }}
    </td>
    <td class="text-right pr-0">
      <label
        class="checkbox checkbox-lg checkbox-outline checkbox-outline-2x checkbox-primary text-right pr-0"
      >
        <input
          type="checkbox"
          name=""
          v-model="local_title.include"
          @click="toggle"
        />
        <span></span>
      </label>
      <span class="ml-3 cursor-pointer"
        ></span
      >
    </td>
  </tr>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'title-table-row',
  props: ['title'],
  emits: ['setIncludeTitle'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {
      local_title: {},
      include: false
    };
  },
  mounted() {
    if (this.title) {
      this.local_title = { ...this.title };
    }
  },
  watch: {
    title: {
      handler(val) {
        this.local_title = { ...val };
      },
      deep: true
    }
  },
  methods: {
    toggle() {
      this.$emit('setIncludeTitle', this.local_title.id, !this.local_title.include);
    }
  }
};
</script>
